<template>  
  <b-container fluid="xl">
    <b-row>
      <NarrowTitle 
      :title="title"
      img="news"
      />
      <b-col cols="12">
        <div class="inner-container">
          <div 
            v-if="entries===null"
            class="empty-div"></div>
          <NoData v-else-if="entries.length <= 0">
            <h2>{{tr("news-message")}}</h2>
          </NoData>
          <div v-else>
            <transition-group name="slide-up" appear>
              <NewsItem
                v-for="(entry, idx) in entries.slice(0, entryNos)"
                :key="'entry' + idx"
                :entry="entry" />
            </transition-group>
          </div>
        </div>
      </b-col>
      <b-col 
        v-if="entries !== null"
        cols="12">
        <div class="news-item__more-btn button-route">
          <SimpleBtn
            type="dark"
            link="null"
            v-if="entryNos < entries.length"
            @click.native="increaseEntries"
            :dlCategory="'news'"
            :dlAction="'previous_news_click'"
            :dlLabel="''"
          >{{tr("news-btn")}}</SimpleBtn>
        
        </div>
        <Contact />
        <FooterBanner />
      </b-col>                      
    </b-row>
  </b-container>
</template>

<script>

import NoData from '@/components/NoData.vue'
import NewsItem from '@/components/NewsItem.vue'
import NarrowTitle from '@/components/NarrowTitle.vue'
import SimpleBtn from '@/components/decors/SimpleBtn.vue'
import Contact from '@/components/Contact'
import FooterBanner from '@/components/FooterBanner'
import apiH from '@/mixins/apiHandlerMI.js'

export default {
  name: 'News',
  mixins: [apiH],
  components: {
      NewsItem,
      SimpleBtn,
      NoData,
      NarrowTitle,
      Contact,
      FooterBanner
  },
  data() {
    return {
      entries: null,
      entryNos: 4,
      increaseEntry: 2,
      }
  },
  methods: {
    increaseEntries () {
      if(this.entryNos + this.increaseEntryNos >= this.entries.length ) {
        this.entryNos = this.entries.length
      } else {
        this.entryNos = this.entryNos + this.increaseEntry
      }      
      
    }
  },
  computed:{
    title(){
      let name
      let menuItems = this.$store.state.menu
      for(let i=0; i<menuItems.length; i++) {
        if(menuItems[i].target_link == 'aktualitasok' || menuItems[i].target_link == 'news') {
          name = menuItems[i].name
        }
      }
      return name
    }
  },
  created () {
    var _this = this
    _this.get('news', {
        params: {                        
            relations: 1,
            where: [['where','status', '1']],   
            order_by:[
                ['created_at','desc']                
            ],                    
        }
      },
        function(resp){       
            _this.entries = resp.data.news
            //_this.entries = []
        }, function(err){
            console.log(err)
            _this.entries = []
        }
    ),
    this.setMetaTags({
          title: "NT Élelmiszertermelő és Kereskedelmi Kft. - Aktuális", 
          desc: "NT Élelmiszertermelő és Kereskedelmi Kft. hírei", 
          keywords: 'NT KFt, NT Élelmiszertermelő és Kereskedelmi Kft, napraforgó étolaj, hírek', 
          ogType: 'website', 
          ogTitle: "NT Élelmiszertermelő és Kereskedelmi Kft. - Aktuális",
          ogImage: null, 
          ogImageWidth: null, 
          ogImageHeight: null, 
          ogDesc:"NT Élelmiszertermelő és Kereskedelmi Kft. aktuális hírei"
      })
  }
}
</script>