<template>
    <div class="news-item">
    
        <div class="news-item__image">
            <img 
                v-if="entry.image !== null"
                :src="imgUrl(entry.image)" alt="test">
        </div>
        <div class="news-item__header">
            <div class="news-item__header--date">
                <p v-if="currentLangCode === 'hu'">{{ entry.updated_at | moment("YYYY. MMMM Do ") }}</p>
                <p v-if="currentLangCode === 'en'">{{ entry.updated_at | moment("Do MMMM YYYY") }}</p>
            </div>
            <div class="news-item__header--title">
                <h2>{{ entry.name }}</h2>
            </div>
            <div class="news-item__header--line"></div>
            <div class="news-item__header--txt">
                <div v-html="entry.description"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['entry'],
    data(){
        return{
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME
        }
    },
    methods: {
        imgUrl: function (data) {
            return this.src_url + data.cache.w730h250 
        }
    }
}
</script>