<template>
    <b-container fluid="xl">
        <div class="title-narrow">
            <b-row>
                <b-col 
                    cols="12" 
                    md="6" 
                    class="title-narrow__box">
                    <div class="title-narrow__img"
                    :style="{ 'background-image': 'url(' + imgUrl + ')' }">
                    </div>
                </b-col>        
                <b-col 
                    cols="12" 
                    md="6" 
                    class="title-narrow__box"> 
                    <div class="title-narrow__title">
                        <h1>{{ title }}</h1>
                    </div>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
export default {
    props: [
        'title', 
        'img'
    ],
    computed:{
        imgUrl(){
            return '/images/' + this.img + '.png'
            }
        }
    }
</script>